<template>
  <v-container fluid class="pageContainer" :class="loading ? 'uniform-container' : ''">
    <div v-if="loading" class="uniform-container">
      <v-progress-circular
        indeterminate
        size="25"
        width="3"
        class="px-6"
        color="primary"
      />
    </div>
    <div
      v-else
      id="uniform-container"
      class="uniform-container"
    >
      <inline-svg
        :src="`${uniform.image}?x-request=xhr`"
        id="uniform"
      />

      <div class="sponsor-arm sponsor-arm-left">
        <img :src="sponsors.arm.image">
      </div>

      <div class="sponsor-chest">
        <img :src="sponsors.chest.image">
      </div>

      <div class="sponsor-arm sponsor-arm-right">
        <img :src="sponsors.arm.image">
      </div>
    </div>
  </v-container>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
const imageOffIcon = require('@/assets/icons/image-off.png')

export default {
  components: {
    InlineSvg
  },
  data () {
    return {
      loading: true,
      uniform: {},
      sponsors: {},
      colors: []
    }
  },
  mounted () {
    window.addEventListener('flutterInAppWebViewPlatformReady', (event) => {
      this.getDataFromApp()
    })
    // this.debugData()
    const { background } = this.$route.query
    document.getElementsByClassName('pageContainer')[0].style.background = `#${background}` ?? '#fff'
  },
  methods: {
    updateElementColorValue (data) {
      var width = document.getElementById('uniform-container').offsetWidth
      var height = document.getElementById('uniform-container').offsetHeight

      var svg = document.getElementById('uniform')

      svg.setAttribute('width', width)
      svg.setAttribute('height', height)
      const elementItens = document
        .getElementById('uniform')
        .getElementsByClassName(this.uniform.elements[data.index].class)

      for (const element of elementItens) {
        element.setAttribute('style', `fill: ${data.hex}`)
      }
    },
    debugData () {
      var result = {
        sponsors: {
          arm: {
            _id: '61d86413b3341a000940baa9',
            name: 'Empecel',
            image: 'https://d1jgxurvv09d2q.cloudfront.net/sponsors/876c2b3e-c514-4257-9731-d4582f33c512.png'
          },
          chest: {
            _id: '61d8fa8ca89f980009206533',
            name: 'Betsul',
            image: 'https://d1jgxurvv09d2q.cloudfront.net/sponsors/8ab813f6-0ee1-4932-87f0-fab66c59c93a.png'
          }
        },
        colors: ['#4D64AF', '#F8F8F8', '#435BAF', '#45AA97'],
        pattern: {
          _id: '61d93ea3db51d60009e6c78f',
          name: 'Uniforme 01',
          elements: [
            {
              _id: '61d93ea3db51d60009e6c790',
              class: 'gola-e-mangas',
              name: 'Gola e Mangas',
              description: ''
            },
            {
              _id: '61d93ea3db51d60009e6c791',
              class: 'bordas',
              name: 'Bordas',
              description: ''
            },
            {
              _id: '61d93ea3db51d60009e6c792',
              class: 'laterais-inferiores',
              name: 'Laterais Inferiores',
              description: ''
            },
            {
              _id: '61d93ea3db51d60009e6c793',
              class: 'peitoral',
              name: 'Peitoral',
              description: ''
            }
          ],
          image: 'https://d1jgxurvv09d2q.cloudfront.net/uniforms/0263345c-c435-4a14-88f7-ced99a6a8456.svg'
        }
      }
      this.uniform = result.pattern
      this.sponsors = result.sponsors
      this.colors = result.colors
      this.loading = false
      setTimeout(() => {
        for (let elementIndex = 0; elementIndex < this.uniform.elements.length; elementIndex++) {
          this.updateElementColorValue({
            index: elementIndex,
            hex: this.colors[elementIndex]
          })
        }
      }, 500)
    },
    getDataFromApp () {
      var self = this
      window.flutter_inappwebview
        .callHandler('getSelectedValues', '')
        .then(function (result) {
          self.uniform = result.pattern
          self.sponsors = result.sponsors
          self.colors = result.colors
          self.loading = false
          setTimeout(() => {
            for (let elementIndex = 0; elementIndex < self.uniform.elements.length; elementIndex++) {
              self.updateElementColorValue({
                index: elementIndex,
                hex: self.colors[elementIndex]
              })
            }
          }, 500)
        })
    },
    imageErrorHandler (index) {
      this.uniforms[index].image = imageOffIcon
    }
  }
}
</script>

<style scoped>
.pageContainer {
  background: #fff;
  height: 100vh;
  padding: 0;
}

.uniform-container {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 300px;
  height: 300px;
}

.sponsor-chest {
  padding: 5px 10px 0 10px;
  position: absolute;
  background: #fff;
  width: 25%;
  border-radius: 8px;
}

.sponsor-chest img {
  width: 100%;
  border-radius: 8px;
}

.sponsor-arm {
  position: absolute;
  width: 14%;
  top: 30%;
  border-radius: 8px;
}

.sponsor-arm img {
  width: 100%;
  border-radius: 5px;
}

.sponsor-arm-right {
  right: 17%;
  transform: rotate(80deg);
}

.sponsor-arm-left {
  left: 17%;
  transform: rotate(-80deg);
}
</style>
